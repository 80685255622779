// 404 Page
import React from 'react'
import { Box, Text } from 'theme-ui'

const PageNotFound = () => {
  return (
    <Box>
      <Text as="h1">404</Text>
    </Box>
  )
}

export default PageNotFound
